import React from 'react';
import { Link } from 'gatsby';
import Mailchimp from 'react-mailchimp-form';
import url from 'helpers/url';

import {
  PageFooter,
  Container,
  Social,
  ScrollTo,
} from '@matchbox-mobile/kenra-storybook';

const {
  StPageFooter,
  StPageFooterTop,
  StPageFooterBottom,
  StPageFooterInner,
  StPageFooterCol,
  StPageFooterSocial,
  StPageFooterNav,
  StPageFooterForm,
  StPageFooterBtnToTop,
  StPageFooterBtnToTopIco,
} = PageFooter;

export default function Footer() {
  return (
    <StPageFooter>
      <StPageFooterTop>
        <Container>
          <StPageFooterInner>
            <StPageFooterCol>
              <StPageFooterSocial>
                <Social
                  items={[
                    {
                      href: 'https://www.instagram.com/kenraprofessional',
                      img:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCggLFyd/3C3eAAACEUlEQVRIx5XVu1LTQRQG8B8Zh4xJJQraWAvYcNFCBR7AwgEZBVvHlouFL2Nhw60XX4ObjBEEpLGxwUaCzCjHIpvAPyQEvq1293zf2d1zWarIGbfkuyNxwTiyZ9GYnDo8sHEhsX6sGawQ28CoRdfBsZ/+ao5rbsuDslc+VhYfKguh5EWSuQgFE7aEcGigcvd1IXxUaEmuomhZCKtyjCfvl6M/9d5rbYrpFGMsCeHlpeiP/BPCG0wIYYE94U+Tu3cZMKCrNn+bYvABBcfCLmVh/xw1b9bnWtA2zMijx5FwYgzsC2XpBbLo9e1c5Lf14L53hpNVSYhGAr1+JdKmOXM20+xAd8auiUA+ef/qcW1tyLYQtrS3FphN9BsZbx1JYrq1QOXpnpx71mEhrLcS6Ep3b4QvQujMCtSX5V3I+DnF+hmLGuoFAtUarUdl9eRigR+gr6FA3xmLjM/sI1Yay9A5+khqJC2jMJPyriNDv2lHCFOtBfIp4tu1lGUkJVfpMolEj4OUvCXz5pNhOHAvY9dUgO7ULs6OUh29JtC4nNtNWzvTg6cyh68glfOecNykoXXq1+9Ww71itaEsCmHCVTEphHmep0ItXoleTLEaJZfuunwFiaJPQlipZPKAw3SKiUs094LJ5P23/mqBPLOUqK2/tjspHmWTlk83Bq1e6XNd0V+vnTNmwW76J5uNsh3zRk8L/j/+cWsA0gmengAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMC0wOFQxMToyMzozOSswMDowMCqzwQcAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTAtMDhUMTE6MjM6MzkrMDA6MDBb7nm7AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==',
                    },
                    {
                      href: 'https://www.youtube.com/user/KenraProfessional',
                      img:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCggLFzISAck1AAABJElEQVRIx+3UvUpDQRAF4C/XGBQLAyIoiIVYCYIIYmMXX0ALGytBEHwNBX0FeysJgp3gA6QRhAQsREQEGyOoIP7GxMZAgrn3JrmdONMsM5yzO2f3LP+RalgPG5WRjUE8+XSr3FxclHen1kHey8vV4TsdQRtzC3Jdw2uqFnpsm06gYCUwm+gS5oNY1aNjNNAX0irYcBpLMMhbiEArSFlSipSxIrS1/LNHYNVFBEUsAaStu0lCAEOthw3a1ntGplW5PYIpB06ajNcQcSNM2vcVJeJ7SGMNY/Z8RF7jCw8hrbJDr7F2KlNM4MaaYqCUxApKgaNEBMeknXU9wLV+mHDeFfzOXP0gAzYVQn35O6su7Rqh+VvvNS5rMOZ1Pvpw5TmRcn8svgF+XpW4g0MibQAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxOS0xMC0wOFQxMToyMzo1MCswMDowMHlEjVMAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTktMTAtMDhUMTE6MjM6NTArMDA6MDAIGTXvAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==',
                    },
                    {
                      href: 'https://www.facebook.com/KenraProfessional',
                      img:
                        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjCggLFjtyxkDQAAABtUlEQVRIx6WVTS9DQRSGn06aKJqyqEQTC23C0sdP0LC6NhYSC/Ev7HXTICJWTSTEDntKLBCbNhGJYilt0RIfC5LeJrSohduP2zv30t4zi5nMvO975sycOeNAZh4UggzSSyfwxg0JjoiS4x/Wzzp5SpKWZ40+a3IrSxSl5HIrsIjLjN7HlSW53OL4qiRHZTTMAV0G0RJRdrjlC4AtvABkUbis9/4s8ZVjTIfKVlYydOtjl29+um4/WV0gLdWFJSk9jbAQKBGqXpz85DcqxFnOSJKsw+XKYaybnHZYo/v4NkFEQOBhyuRaC1rvrbkrvc3gFii0/ZGbDtOVdhQnQcP0J3cAvFZ2ktKE/AZsEE4NkWVN/UmyUkhUG7GAwGNLoEPYogPwYvrq5jTEgMXLfBKkbblPCRK2BC4ER7YEDgW75Jum59kXqGw3LbCJKoAFik3RC8yDAK5ZaUpgWXsjgIt4w3kQ+y1pv5n4zgSZhrw/MMkHgFObeGScKD06UIBRra+3DAr3RtUuTv71scT0Jb3WWgihWpI/CNeWc5l1EzERUVk1hiOvd24URhjCr33vac45Zg/VCP0B3Z5D8fAhwn8AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMTAtMDhUMTE6MjI6NTkrMDA6MDADHqO+AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTEwLTA4VDExOjIyOjU5KzAwOjAwckMbAgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=',
                    },
                  ]}
                />
              </StPageFooterSocial>
              <StPageFooterNav>
                <ul>
                  <li>
                    <Link to={url.contact}>CONTACT</Link>
                  </li>
                  <li>
                    <Link to={url.aboutUs}>ABOUT</Link>
                  </li>
                  <li>
                    <Link to={url.faq}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={url.carryUs}>CARRY US</Link>
                  </li>
                </ul>
              </StPageFooterNav>
            </StPageFooterCol>
            <StPageFooterCol>
              <StPageFooterForm>
                <Mailchimp
                  action="https://kenraprofessional.us13.list-manage.com/subscribe/post?u=ff97c13369a24c48b7915af18&id=baa8ca533a"
                  fields={[
                    {
                      name: 'EMAIL',
                      placeholder: 'Email',
                      type: 'email',
                      required: true,
                    },
                  ]}
                />
              </StPageFooterForm>
              <StPageFooterBtnToTop>
                <ScrollTo toPosition={0}>
                  <StPageFooterBtnToTopIco />
                  TOP PAGE
                </ScrollTo>
              </StPageFooterBtnToTop>
            </StPageFooterCol>
          </StPageFooterInner>
        </Container>
      </StPageFooterTop>
      <StPageFooterBottom>
        <Container>
          © 2020 Kenra Professional, a division of Henkel Corporation |{' '}
          <Link to={url.privacy}>Privacy</Link> |{' '}
          <Link to={url.termsOfUse}>Terms of Use</Link> |{' '}
          <Link to={url.accessibility}>Accessibility</Link>
        </Container>
      </StPageFooterBottom>
    </StPageFooter>
  );
}
