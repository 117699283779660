import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import Helmet from 'react-helmet';

const Layout = ({ children, hideFooter }) => {
  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Helmet>
          <title>Kenra Professional</title>
          <form name="contact" netlify netlify-honeypot="bot-field" hidden>
            <input type="text" name="name" />
            <input type="email" name="email" />
            <textarea name="message"></textarea>
          </form>
        </Helmet>
        <Header />
        <main
          style={{
            flexGrow: 1,
          }}
        >
          {children}
        </main>
        {!hideFooter && <Footer />}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
