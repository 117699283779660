import React from 'react';
import { Link } from 'gatsby';

export default function CustomLink(props) {
  let { children, href, ...restProps } = props;
  if (href && href.substring(0, 4) === 'http') {
    return <a href={href}>{children}</a>;
  }
  return (
    <Link to={href} {...restProps}>
      {children}
    </Link>
  );
}
