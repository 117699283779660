import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash.get';

import { PageHeader } from '@matchbox-mobile/kenra-storybook';
import Link from 'components/Link';
import { getCollectionUrl } from 'helpers/url';

function processMenu(json) {
  json.map(block => {
    return (
      block.items &&
      block.items.map(item => {
        return (
          item.items &&
          item.items.map(subitem => {
            if (!subitem.href && subitem.collection) {
              subitem.href = getCollectionUrl(subitem.collection);
            }
            return null;
          })
        );
      })
    );
  });

  return json;
}

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      contentfulMenu(slug: { eq: "top" }) {
        ...ContentfulMenuFields
      }
    }
  `);

  let { contentfulMenu } = data;

  let navItems = processMenu(contentfulMenu.json);
  navItems[0].promo = {
    img: get(contentfulMenu, 'headerImage.localFile.publicURL'),
    title: contentfulMenu.headerTitle,
    text: get(contentfulMenu, 'headerText.headerText'),
    linkText: get(contentfulMenu, 'headerLinkText'),
    href: contentfulMenu.headerLink,
  };

  let logo = { src: '/images/logo.png', href: '/', alt: 'Kenra' };

  return <PageHeader Link={Link} logo={logo} navItems={navItems} />;
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
