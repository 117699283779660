export function getProductUrl(handle) {
  return `/product/${handle}`;
}

export function getCollectionUrl(handle) {
  return `/collection/${handle}`;
}

export function getVideoUrl(slug) {
  return `/video/${slug}`;
}

export function getBlogPostUrl(contentful_id) {
  return `/blog/${contentful_id}`;
}

export default {
  contact: '/contact',
  quiz: '/quiz',
  carryUs: '/carry-us',
  videos: '/video',
  faq: '/faq',
  blog: '/blog',
  aboutUs: '/about-us',
  tools: '/tools',
  applications: '/applications',
  privacy: '/privacy',
  termsOfUse: '/terms-of-use',
  accessibility: '/accessibility',
  colorList: '/color-list',
};
